<template>
  <div v-if="getSelectedBusiness" class="kybStatus widgetContainer widgetContainer--center  bg-white">
    <span
      class="icon-Arrow-big-left back-btn"
      @click="$router.push('/account/create')"
      v-if="showBackBtn" />
    <div
      class="
        widgetContainer widgetContainer--center
        bg-white
        widgetContainer--scrollable widgetContainer--full-screen
        appStatus appStatus--approved
      "
      v-if="
        getSelectedBusiness.kyb.status === 'approved'
      ">
      <div class="widgetContainer__body">
        <template
          v-if="
            ['soleProprietor', 'singleMemberLLC'].includes(
              getSelectedBusiness.entityType
            )
          ">
          <LottieAnimation animation-name="success" reference="business-status-approved-1" :loop="true" />
          <p class="title">
            {{ $t('kybStatus_approved_title') }}
          </p>
          <p class="description">
            {{ $t('kybStatus_approved_description_soleProp') }}
          </p>
          <div class="widgetContainer__footer--fixed">
            <el-button
              type="primary"
              class="welcome__get-started el-button__brand brand"
              @click="proceedToAccountCreation">
              {{ $t('kybStatus_approved_buttonTitle_without_sign') }}
            </el-button>
          </div>
        </template>
        <template v-else-if="(getSelectedBusiness.disclosureStatus === 'notStarted' ||
          getSelectedBusiness.disclosureStatus === 'pending')">
          <template
            v-if="
              !['soleProprietor', 'singleMemberLLC'].includes(
                getSelectedBusiness.entityType
              )
            ">
            <LottieAnimation animation-name="success" reference="business-status-approved-2" :loop="true" />
            <p class="title">
              {{ $t('kybStatus_approved_title') }}
            </p>
            <p class="description">
              {{ $t('kybStatus_approved_description_Multimember') }}
            </p>
            <div class="widgetContainer__footer--fixed">
              <el-button
                type="primary"
                class="welcome__get-started el-button__brand brand"
                @click="proceedToDeclareOwners">
                {{ $t('kybStatus_approved_buttonTitle_Multimember') }}
              </el-button>
            </div>
          </template>
          <template v-else>
            <LottieAnimation animation-name="success" reference="business-status-approved-3" :loop="true" />
            <p class="title">
              {{ $t('kybStatus_approved_title') }}
            </p>
            <p class="description">
              {{ $t('kybStatus_approved_description') }}
            </p>
            <div class="widgetContainer__footer--fixed">
              <el-button
                v-if="isHellosignEnabled"
                type="primary"
                class="welcome__get-started el-button__brand brand"
                @click="proceedToSignIn">
                {{ $t('kybStatus_approved_buttonTitle') }}
              </el-button>

              <el-button
                v-else
                type="primary"
                class="welcome__get-started el-button__brand brand"
                @click="proceedToAccountCreation">
                {{ $t('kybStatus_approved_buttonTitle_without_sign') }}
              </el-button>
            </div>
          </template>
        </template>
      </div>
    </div>

    <div
      class="
        widgetContainer widgetContainer--center
        bg-white
        widgetContainer--scrollable widgetContainer--full-screen
        appStatus appStatus--declined
      "
      v-else-if="getSelectedBusiness.kyb.status === 'declined'">
      <div class="widgetContainer__body">
        <LottieAnimation animation-name="failure" reference="business-status-failure-1" :loop="true" />
        <p class="title">
          {{ $t('kybStatus_declined_title') }}
        </p>
        <p class="description">
          {{ $t('kybStatus_declined_description') }}
        </p>
        <div class="widgetContainer__footer--fixed">
          <el-button type="primary" class="welcome__get-started el-button__brand brand" @click="next">
            {{ $t('kybStatus_declined_buttonTitle') }}
          </el-button>
        </div>
      </div>
    </div>

    <div
      class="
        widgetContainer widgetContainer--center
        bg-white
        widgetContainer--full-screen
        verifying
        appStatus appStatus--verifying
      "
      v-else-if="getSelectedBusiness.kyb.status === 'submitted' && isLoading">
      <div class="widgetContainer__body">
        <LottieAnimation animation-name="searching" reference="business-status-review-1" :loop="true" />
        <p class="title">
          {{ $t('kybStatus_waiting_title') }}
        </p>
      </div>
    </div>

    <div
      class="
        widgetContainer widgetContainer--center
        bg-white
        widgetContainer--scrollable widgetContainer--full-screen
        appStatus appStatus--verifying
      "
      v-else>
      <div class="widgetContainer__body">
        <LottieAnimation animation-name="searching" reference="business-status-review-2" :loop="true" />
        <p class="title">
          {{ $t('kybStatus_inReview_title') }}
        </p>
        <p class="description">
          {{ $t('kybStatus_inReview_description') }}
        </p>
        <div class="widgetContainer__footer--fixed">
          <el-button
            type="primary"
            class="welcome__get-started el-button__brand brand"
            @click="openHelp">
            {{ $t('kybStatus_inReview_buttonTitle') }}
          </el-button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapActions, mapGetters, mapMutations } from 'vuex';
import LottieAnimation from '@/components/LottieAnimation.vue';

export default {
  name: 'BusinessStatus',
  components: {
    LottieAnimation
  },
  computed: {
    ...mapGetters('account', ['getAllAccount']),
    ...mapGetters('kyb', ['getSelectedBusiness', 'getKybDetails']),
    showBackBtn() {
      return (
        this.$businessChecking === true &&
        this.$personalChecking === true &&
        this.getAllAccount.length !== 0
      );
    }
  },
  data() {
    return {
      isLoading: true,
      retryCount: 0,
      timer: null
    };
  },
  beforeDestroy() {
    if(this.timer) {
      clearTimeout(this.timer);
    }
  },
  created() {
    /** @type {import('@m/kyb/store/state').kybState['selectedBusinessType']}*/
    const selectedBusiness = this.getSelectedBusiness;
    if (selectedBusiness) {
      this.fetchKyb(selectedBusiness.id);
    } else {
      const loader = this.showLoader();
      this.listAllBusiness()
        .then((r) => {
          if (r.total !== 0) {
            /** @type {import('@m/kyb/store/state').kybState['bussinessDetails']} */
            const businessList = r.data;
            this.setSelectedBusiness(businessList[0]);
            this.timer = setTimeout(() => {
              this.fetchKyb(businessList[0].id);
            }, 0);
          }
        })
        .catch((e) => {
          console.log(e);
          this.apiErrorMessage(e);
        })
        .finally(() => {
          loader.close();
        });
    }
  },
  methods: {
    ...mapActions('kyb', ['fetchBusiness', 'listAllBusiness']),
    ...mapMutations('kyb', ['setSelectedBusiness']),
    ...mapMutations('account', ['updateCreateAccountData']),
    /**
     * @param {string} businessId
     */
    fetchKyb(businessId, showLoader = true) {
      const loader = showLoader ? this.showLoader() : {};
      this.fetchBusiness(businessId)
        .then((data) => {
          /** @type {import('@m/kyb/store/state').kybState['selectedBusinessType']} */
          const businessData = data;
          this.setSelectedBusiness(businessData);
          if (businessData && businessData.kyb.status === 'submitted') {
            if (this.retryCount < 5) {
              this.isLoading = true;
              this.retryCount++;
              this.timer = setTimeout(() => {
                this.fetchKyb(businessId);
              }, 5000);
            } else {
              this.isLoading = false;
              this.timer = setTimeout(() => {
                // without loading indicator
                this.fetchKyb(businessId, false);
              }, 5000);
            }
          } else {
            
            this.isLoading = false;
            if (businessData && businessData.kyb.status !== 'approved') {
              this.timer = setTimeout(() => {
                // without loading indicator
                this.fetchKyb(businessId, false);
              }, 5000);
            }
          }
        })
        .catch((e) => {
          this.apiErrorMessage(e);
        })
        .finally(() => {
          if (showLoader && loader.close) loader.close();
        });
    },
    proceedToSignIn() {
      window.analytics && window.analytics.track('proceedSign');

      this.$router.push('/kyb/helloSign');
    },
    proceedToAccountCreation() {
      
      this.updateCreateAccountData({
        businessId: this.getSelectedBusiness.id,
        type: 'businessChecking'
      });
      this.$router.push('/account/setup');
    },
    next() {
      // this.$router.push('/home');
    },
    proceedToDeclareOwners() {
      this.$router.push('/kyb/ownership');
    },
  }
};
</script>

<style lang="scss">
.kybStatus {
  .back-btn {
    padding: 30px 0 0 10px;
    cursor: pointer;
  }
  .welcome__illustration {
    display: block;
  }
  .kybStatus_footer {
    position: absolute;
    bottom: 0;
    padding: 16px 12px;
    width: 370px;
    background-color: white;
    .el-button {
      width: 100%;
    }
  }
  .welcome__get-started {
    min-width: 100%;
    min-height: 40px;
  }

  .welcome__logo {
    margin-bottom: 40px;
  }

  .welcome__description {
    padding-bottom: 80px;
  }

  .is-circle {
    min-height: 40px;
  }
}
.widgetContainer.appStatus {
  margin-top: 0;

  .description {
    padding-bottom: 100px;
  }
}
</style>
